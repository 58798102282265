<script setup lang="ts">import { computed as _computed, toRef as _toRef, ref as _ref } from 'vue';

import type { ErrorResponse } from '@nuxtjs/apollo'
import { renderSVG } from 'uqr'

definePageMeta({
  title: '登录',
  async middleware() {
    const { token } = useLoginStore()
    if (token)
      return navigateTo('/dashboard')
  },
})

const { onLogin } = useLoginStore()

const route = useRoute()
const router = useRouter()
const url = useRequestURL()

function onCSRFDetected() {
  throw createError({
    statusCode: 400,
    statusMessage: '检测到跨站请求伪造攻击，请重新登录',
  })
}

const return_url = route.query.return_url as string ?? '/dashboard'
if (!(return_url.startsWith('/') || new URL(return_url).origin === url.origin))
  onCSRFDetected()

const isQRCode = _computed({
  get: () => route.query.type === 'qrcode',
  set: value => router.replace(
    { query: { ...route.query, type: value ? 'qrcode' : 'oauth2' } },
  ),
})
const fields = computed(() => (
  isQRCode.value
    ? [{ name: 'qrcode', type: '', label: '' }]
    : []
))

let state = (useCookie('state'))
state.value ||= crypto.randomUUID()
const query = new URLSearchParams({
  navhide: '1',
  gourl: url.href,
  callback: 'skip',
  state: state.value,
  client_id: useRuntimeConfig().public.clientId,
}).toString()
const providers = computed(() =>
  isQRCode.value
    ? []
    : [{
        to: `https://account.bilibili.com/h5/account-h5/auth/oauth?${query}`,
        label: 'bilibili开放平台',
        icon: 'ri:bilibili-fill',
        variant: 'outline' as const,
        loading: !!route.query.code,
      }],
)

const { mutate } = useMutation(
  graphql(/* GraphQL */ `
    mutation login($input: LoginInput!) {
      login(input: $input)
    }
  `),
)

if (route.query.code) {
  if (route.query.state !== state.value)
    onCSRFDetected()

  state.value = null
  const token = (await mutate({ input: { code: route.query.code as string } }))
    ?.data
    ?.login

  if (!token) {
    throw createError({
      statusCode: 500,
      statusMessage: '登录失败，请重试',
    })
  }

  await onLogin(token)
  await navigateTo(return_url, { external: true })
}

let interval: NodeJS.Timeout
const __$temp_1 = (useFetch(
  '/api/bilibili/login/qrcode/generate',
  { immediate: false },
)),
  data = _toRef(__$temp_1, 'data'),
  execute = _toRef(__$temp_1, 'execute');
const errors: Record<string, Record<'icon' | 'message', string>> = {
  二维码已失效: {
    icon: 'material-symbols:refresh-rounded',
    message: '二维码已过期<br>请点击刷新',
  },
  二维码已扫码未确认: {
    icon: 'material-symbols:check-rounded',
    message: '扫码成功<br>请在手机上确认',
  },
}
let message = _ref('二维码已失效')
async function refresh() {
  await execute.value()
  message.value = '未扫码'
}
watch(
  (isQRCode),
  async () => {
    if (import.meta.server)
      return

    clearInterval(interval)

    if (!isQRCode.value)
      return

    if (message.value === '二维码已失效')
      await refresh()

    interval = setInterval(
      async () => {
        if (!(message.value === '未扫码' || message.value === '二维码已扫码未确认'))
          return

        let token: string | undefined

        try {
          token = (await mutate({ input: { qrcodeKey: data.value?.qrcode_key } }))
            ?.data
            ?.login
        }
        catch (e) {
          message.value = (e as ErrorResponse).graphQLErrors![0]!.message
          return
        }

        clearInterval(interval)

        if (!token) {
          throw createError({
            statusCode: 500,
            statusMessage: '登录失败，请重试',
          })
        }
        await onLogin(token)
        await navigateTo(return_url, { external: true })
      },
      1000,
    )
  },
  { immediate: true },
)
onDeactivated(() => clearInterval(interval))
</script>

<template>
  <div
    class="
      min-h-[calc(100vh-var(--header-height))] p-4
      flex flex-col justify-center items-center gap-4
    "
  >
    <UPricingToggle
      v-model="isQRCode"
      class="max-w-sm w-full"
      left="用户登录"
      right="主播登录"
    />

    <UCard class="max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur">
      <UAuthForm
        align="top"
        icon="heroicons:lock-closed"
        title="登录"
        divider="或"
        :fields
        :providers
        :submit-button="{ class: 'hidden' }"
        :ui="{
          base: 'text-center',
          icon: { base: 'mx-auto' },
          footer: 'text-center',
        }"
      >
        <template #description>
          使用哔哩哔哩账号登录
        </template>

        <template #qrcode-field>
          <p>请使用哔哩哔哩APP扫码或点击二维码跳转登录</p>

          <div class="relative mt-4">
            <button
              v-if="message !== '未扫码'"
              class="
                absolute w-full h-full
                flex flex-col items-center justify-center gap-2
                bg-gray-300/60 backdrop-blur text-gray-900
              "
              :disabled="message !== '二维码已失效'"
              @click="refresh"
            >
              <Icon :name="errors[message]?.icon ?? ''" class="size-10" />

              <p class="text-gray-900" v-html="errors[message]?.message" />
            </button>

            <NuxtLink :to="data?.url" target="_blank">
              <div v-html="renderSVG(data?.url ?? '')" />
            </NuxtLink>
          </div>
        </template>

        <template #footer>
          登录即代表您同意
          <NuxtLink to="/blog/legal#用户协议" class="text-primary font-medium">
            用户协议
          </NuxtLink>
          和
          <NuxtLink to="/blog/legal#_3-隐私政策" class="text-primary font-medium">
            隐私政策
          </NuxtLink>
        </template>
      </UAuthForm>
    </UCard>
  </div>
</template>
